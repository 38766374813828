import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { depotGuard } from './guards/depot.guard';
import { permissionGuard } from './guards/permission.guard';
import { routePathGuard } from './guards/route-path.guard';

export const routes: Routes = [
	{
		path: ':lang/complete',
		loadComponent: () => import('./pages/complete-page/complete-page.component').then((c) => c.CompletePageComponent),
		canActivate: [authGuard],
	},
	{
		path: ':lang/cart',
		loadComponent: () =>
			import('./pages/cart-page/components/cart-page-picker/cart-page-picker.component').then(
				(c) => c.CartPagePickerComponent,
			),
		pathMatch: 'full',
		canActivate: [authGuard, depotGuard],
	},
	{
		path: ':lang/cart/complete',
		loadComponent: () =>
			import('./pages/cart-complete-page/cart-complete-page.component').then((c) => c.CartCompletePageComponent),
		pathMatch: 'full',
		canActivate: [authGuard, depotGuard],
	},
	{
		path: ':lang/category/:categoryName',
		loadComponent: () => import('./pages/category-page/category-page.component').then((c) => c.CategoryPageComponent),
		pathMatch: 'full',
	},

	{
		path: ':lang/category/:categoryName/product/:productId/:productName',
		loadComponent: () =>
			import('./pages/product-page/components/product-page-picker/product-page-picker.component').then(
				(c) => c.ProductPagePickerComponent,
			),
		pathMatch: 'full',
	},
	{
		path: ':lang/depot',
		loadComponent: () => import('./pages/depot-map-page/depot-map-page.component').then((m) => m.DepotMapPageComponent),
		pathMatch: 'full',
		canActivate: [depotGuard],
	},
	{
		path: ':lang/invoices',
		loadComponent: () => import('./pages/invoices-page/invoices-page.component').then((c) => c.InvoicesPageComponent),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('LIST_INVOICES')],
	},
	{
		path: ':lang/account',
		loadComponent: () => import('./pages/account-page/account-page.component').then((m) => m.AccountPageComponent),
		pathMatch: 'full',
		canActivate: [authGuard, depotGuard],
	},
	{
		path: ':lang/account/phone',
		loadComponent: () =>
			import('./pages/account-change-phone-page/account-change-phone-page.component').then(
				(c) => c.AccountChangePhonePageComponent,
			),
		canActivate: [authGuard],
	},
	{
		path: ':lang/jobsite/:jobsiteId',
		loadComponent: () => import('./pages/jobsite-page/jobsite-page.component').then((m) => m.JobsitePageComponent),
		pathMatch: 'full',
		canActivate: [authGuard],
	},
	{
		path: ':lang/statistics',
		loadComponent: () =>
			import('./pages/statistics-page/statistics-page.component').then((c) => c.StatisticsPageComponent),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('STATISTICS_LIST_PRESENTATION')],
	},
	{
		path: ':lang/notifications',
		loadComponent: () =>
			import('./pages/notifications-page/notifications-page.component').then((c) => c.NotificationsPageComponent),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('LIST_ORDER_LINES')],
	},
	{
		path: ':lang/savedproducts',
		loadComponent: () =>
			import('./pages/savedproducts-page/savedproducts-page.component').then((c) => c.SavedProductsPageComponent),
		pathMatch: 'full',
		canActivate: [authGuard],
	},
	{
		path: ':lang/users',
		loadComponent: () => import('./pages/users-page/users-page.component').then((c) => c.UsersPageComponent),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('ADMINISTRATE_USERS')],
	},
	{
		path: ':lang/jobsites',
		loadComponent: () => import('./pages/jobsites-page/jobsites-page.component').then((c) => c.JobsitesPageComponent),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('VIEW_JOB_SITES')],
	},
	{
		path: ':lang/search',
		loadComponent: () => import('./pages/search-page/search-page.component').then((c) => c.SearchPageComponent),
	},
	{
		path: ':lang/registration',
		loadComponent: () =>
			import('./pages/registration-page/registration-page.component').then((c) => c.RegistrationPageComponent),
		pathMatch: 'full',
	},
	{
		path: ':lang/registration/:sign',
		loadComponent: () =>
			import('./pages/registration-page/registration-page.component').then((c) => c.RegistrationPageComponent),
		pathMatch: 'full',
	},
	{
		path: ':lang/digital-account-application',
		loadComponent: () =>
			import('./pages/digital-account-application-page/digital-account-application-page.component').then(
				(c) => c.DigitalAccountApplicationPageComponent,
			),
	},
	{
		path: ':lang/digital-account-application/success',
		loadComponent: () =>
			import(
				'./pages/digital-account-application-page/components/digital-account-application-success-page/digital-account-application-success-page.component'
			).then((c) => c.DigitalAccountApplicationSuccessPageComponent),
	},
	{
		path: ':lang/account-not-found',
		loadComponent: () =>
			import('./pages/account-not-found-page/account-not-found-page.component').then(
				(c) => c.AccountNotFoundPageComponent,
			),
	},
	{
		path: ':lang/notfound',
		children: [
			{
				path: '**',
				loadComponent: () =>
					import('./pages/notfound-page/notfound-page.component').then((c) => c.NotfoundPageComponent),
			},
		],
	},
	{
		path: ':lang/punchout/:punchoutSessionId',
		loadComponent: () => import('./pages/punchout-page/punchout-page.component').then((c) => c.PunchoutPageComponent),
		pathMatch: 'full',
	},
	{
		path: ':lang/cookie-declaration',
		loadComponent: () =>
			import('./pages/cookie-declaration-page/cookie-declaration-page.component').then(
				(c) => c.CookieDeclarationPageComponent,
			),
	},
	{
		path: ':lang/feature-toggles',
		loadComponent: () =>
			import('./pages/feature-toggles-page/feature-toggles-page.component').then((c) => c.FeatureTogglesPageComponent),
	},
	{
		path: ':lang',
		loadComponent: () => import('./pages/cms-page/cms-page.component').then((c) => c.CmsPageComponent),
		canActivate: [routePathGuard],
	},
	{
		path: ':lang/:contentUrl',
		children: [
			{
				path: '**',
				loadComponent: () => import('./pages/cms-page/cms-page.component').then((c) => c.CmsPageComponent),
				data: { forceReloadOnChange: true },
			},
		],
	},
	{
		path: '**',
		pathMatch: 'full',
		loadComponent: () => import('./pages/notfound-page/notfound-page.component').then((c) => c.NotfoundPageComponent),
		canActivate: [routePathGuard],
	},
];
