@if (isOpened) {
	<div
		class="menu-expanded bg-color-secondary text-color-white"
		[cramoClickOutside]="isOpened"
		(byOutsideClicked)="closeMenu()"
		@menuVisibility
		(@menuVisibility.done)="onCloseEnd()"
	>
		<button id="close-button" (click)="closeMenu()" class="button-icon text-color-white ml-auto">
			<cramo-icon iconName="close" />
		</button>

		@if (mainMenuState) {
			<div class="menu-content pl-4 pr-4 pb-4" [@slideMainMenu]="mainMenuState">
				<div class="font-weight-light">{{ localizedLabels.welcomeText }}</div>

				<div class="text-color-white">
					<h2 class="mb-0 font-weight-normal">{{ user()?.FirstName }} {{ user()?.LastName }}</h2>
					<button
						*cramoHasPermission="'VIEW_CUSTOMER_DATA'"
						id="view-account"
						class="button text-color-white pl-0 my-0 ml-0"
						[routerLink]="[currentLanguage + '/account']"
						(click)="closeMenu()"
					>
						{{ localizedLabels.viewAccountText }}
						<cramo-icon iconName="arrow_forward" [size]="20" />
					</button>
				</div>

				<div class="divider"></div>

				<div id="change-customer" class="text-color-white text-left">
					<h3 class="mb-0 pt-2 font-weight-light">{{ user()?.CustomerInfo?.CompanyName }}</h3>
					@if (user()?.CanChooseCustomer) {
						<button class="button text-color-white pl-0 my-0 ml-0" (click)="showCustomers($event)">
							{{ localizedLabels.changeCustomerText }}
							<cramo-icon iconName="arrow_forward" [size]="20" />
						</button>
					}
				</div>

				<ul class="list-menu mt-3">
					<li class="list-menu-object" *cramoHasPermission="'VIEW_JOB_SITES'">
						<button class="h-100" [routerLink]="[currentLanguage + '/jobsites']" (click)="closeMenu()">
							<div class="list-menu-icon icon-job-site"></div>
							<div>{{ localizedLabels.jobsitesText }}</div>
						</button>
					</li>

					@if (featureToggles().other.areNotificationsEnabled) {
						<li class="list-menu-object" *cramoHasPermission="'LIST_ORDER_LINES'">
							<button
								class="h-100"
								[routerLink]="[currentLanguage + '/notifications']"
								(click)="closeMenu()"
								data-test-id="sidenav-button-notifications"
							>
								<div class="list-menu-icon icon-bell"></div>
								<div>{{ localizedLabels.notification }}</div>
								@if (notificationCount() > 0) {
									<div class="menu-notification-badge">
										{{ notificationCount() | maxNumber: 99 }}
									</div>
								}
							</button>
						</li>
					}

					@if (featureToggles().other.areSavedProductsEnabled) {
						<li class="list-menu-object">
							<button [routerLink]="[currentLanguage + '/savedproducts']" (click)="closeMenu()">
								<div class="list-menu-icon icon-favorites"></div>
								<div>{{ localizedLabels.savedProducts }}</div>
								@if (showSavedProductsCount()) {
									<div class="menu-notification-badge">
										{{ savedProductsCount() | maxNumber: 99 }}
									</div>
								}
							</button>
						</li>
					}
					<li *cramoHasPermission="'LIST_INVOICES'" class="list-menu-object">
						<button class="h-100" [routerLink]="[currentLanguage + '/invoices']" (click)="closeMenu()">
							<div class="list-menu-icon icon-invoices"></div>
							<div>{{ localizedLabels.invoiceText }}</div>
						</button>
					</li>
					<li *cramoHasPermission="'STATISTICS_LIST_PRESENTATION'" class="list-menu-object">
						<button class="h-100" [routerLink]="[currentLanguage + '/statistics']" (click)="closeMenu()">
							<div class="list-menu-icon icon-statistics"></div>
							<div>{{ localizedLabels.statisticsText }}</div>
						</button>
					</li>
					@if (featureToggles().other.isUserAdministrationEnabled) {
						<li *cramoHasPermission="'ADMINISTRATE_USERS'" class="list-menu-object">
							<button class="h-100" [routerLink]="[currentLanguage + '/users']" (click)="closeMenu()">
								<div class="list-menu-icon icon-admin-users"></div>
								<div>{{ localizedLabels.administrateUsers }}</div>
							</button>
						</li>
					}
				</ul>

				@if (featureToggles().other.isSmartControlAccessEnabled) {
					@if (user()?.HasSmartControlAccess) {
						<button (click)="goToSmartControl()" class="button bg-color-smartcontrol-button w-100">
							<div class="font-family-medium">{{ localizedLabels.smartControlButtonText }}</div>
							<cramo-icon iconName="open_in_new" />
						</button>
					}
				}

				<cramo-button
					(onclick)="logout()"
					id="log-out"
					data-test-id="sidenav-button-logout"
					[text]="localizedLabels.signOutText"
					[suffixIcon]="'logout'"
					[fullWidth]="true"
				/>
			</div>
		}

		@if (selectCustomerState) {
			<div class="menu-change-customer" [@slideSelectCustomer]="selectCustomerState">
				<div class="d-flex align-items-start pt-4 pl-4 pr-4">
					<button (click)="showMenu($event)" class="button m-0 pl-0 text-color-white">
						<cramo-icon iconName="arrow_backward" [size]="20" />
						<span>{{ localizedLabels.backToMenuText }}</span>
					</button>
				</div>
				<div class="pt-4">
					<div class="pl-4 pr-4">
						<h2 class="font-weight-normal mb-4">{{ localizedLabels.changeCustomerText }}</h2>
					</div>

					<ul class="list-menu-change-customer customer-container mt-5 pl-4">
						@if (userCustomers$ | async; as customers) {
							<div>
								@for (customer of customers; track customer.CustomerId) {
									<li class="list-menu-object">
										<div class="d-flex justify-content-between align-items-center">
											<div class="custom-control custom-radio" (click)="setCustomer(customer)">
												<input
													type="radio"
													name="customRadio"
													class="custom-control-input"
													[checked]="customer.CustomerId === selectedCustomerId"
												/>
												<label class="custom-control-label">
													<span>{{ customer.CompanyName }}</span>
													<span class="text-assitive text-color-grey">{{ customer.Address.CityName }}</span>
												</label>
											</div>
											<button
												class="text-color-white mr-4 customer-info-expand"
												[ngClass]="{ 'rotate-180': isCustomerExpanded[customer?.CustomerId ?? ''] }"
												(click)="invertCustomerDetailsVisibility(customer.CustomerId)"
											>
												<cramo-icon iconName="expand_more" [size]="32" />
											</button>
										</div>

										<div
											class="customer-details p-3"
											[ngClass]="
												isCustomerExpanded[customer.CustomerId]
													? 'customer-details-expanded'
													: 'customer-details-collapsed'
											"
										>
											<div>
												<span class="font-weight-light">{{ localizedLabels.customerTypeText }}</span>
												<span>{{ customer.Type }}</span>
											</div>
											<div>
												<span class="font-weight-light">{{ localizedLabels.customerVatText }}</span>
												<span>{{ customer.Vat }}</span>
											</div>
											<div>
												<span class="font-weight-light">{{ localizedLabels.customerInvoiceNumberText }}</span>
												<span>{{ customer.InvoiceCustomerNumber }}</span>
											</div>
										</div>
									</li>
								}
							</div>
						} @else {
							<cramo-loading-area
								[title]="localizedLabels.gettingCustomersText"
								zIndex="-1"
								[backgroundColor]="'#193255'"
								[dotColor]="'#FFF'"
								[textColor]="'#FFF'"
							/>
						}
					</ul>
				</div>
			</div>
		}

		@if (settingCustomer) {
			<cramo-loading-area
				[title]="localizedLabels.switchingCustomerText"
				zIndex="2"
				[backgroundColor]="'#193255'"
				[textColor]="'#FFF'"
			/>
		}
	</div>
}
