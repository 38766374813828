import { DatePipe, DecimalPipe } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withInterceptors,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom, LOCALE_ID, Provider } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadingStrategy, provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { tap } from 'rxjs';
import { routes } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { EcomPreloadingStrategy } from './app/ecom-preloading-strategy';
import { EcomRouteReuseStrategy } from './app/ecom-route-reuse-strategy';
import { AuthInterceptor, ErrorInceptor, InactivityInterceptor, ReqFormatInterceptor } from './app/interceptors';
import { LanguageInterceptor } from './app/interceptors/language.interceptor';
import { marketInterceptor } from './app/interceptors/market.interceptor';
import { ConfigurationService } from './app/services/configuration.service';
import { DialogService } from './app/services/dialog/dialog.service';
import { EpiService } from './app/services/epi/epi.service';
import { MarketService } from './app/services/market.service';
import { Configurations } from './app/types/configuration.types';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

const appInitializer: Provider = {
	provide: APP_INITIALIZER,
	deps: [EpiService, HttpClient, ConfigurationService, DialogService],
	multi: true,
	useFactory: (epiService: EpiService, httpClient: HttpClient, configurationService: ConfigurationService) => {
		return () => {
			return httpClient.get<Configurations>(`/webapi/configurations`).pipe(
				tap((data) => {
					// eslint-disable-next-line no-param-reassign
					epiService.configurationData = data;
					configurationService.initializeConfiguration(data);
				}),
			);
		};
	},
};

const localeId: Provider = {
	provide: LOCALE_ID,
	deps: [MarketService],
	useFactory: (marketService: MarketService) => marketService.getLocaleId(),
};

const materialDialogOptions: Provider = {
	provide: MAT_DIALOG_DEFAULT_OPTIONS,
	useValue: {
		autoFocus: false, // Globally disables autofocus for all dialogs
	},
};

// Interceptors are applied in the order they are provided:
// - On outgoing requests, they are applied in this order (from top to bottom).
// - On incoming responses, they are applied in reverse order (from bottom to top).
// The first interceptor in the list processes requests first and the last one processes responses first.
const interceptors: Provider[] = [
	LanguageInterceptor,
	AuthInterceptor,
	ReqFormatInterceptor,
	ErrorInceptor,
	InactivityInterceptor,
].map((interceptor) => ({
	provide: HTTP_INTERCEPTORS,
	useClass: interceptor,
	multi: true,
}));

bootstrapApplication(AppComponent, {
	providers: [
		provideRouter(routes, withPreloading(EcomPreloadingStrategy)),
		appInitializer,
		localeId,
		materialDialogOptions,
		...interceptors,
		provideHttpClient(withInterceptors([marketInterceptor]), withInterceptorsFromDi()),
		provideAnimations(),
		importProvidersFrom(ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false })),
		{ provide: PreloadingStrategy, useClass: EcomPreloadingStrategy },
		{ provide: RouteReuseStrategy, useClass: EcomRouteReuseStrategy },
		DatePipe,
		DecimalPipe,
	],
}).catch((error) => console.info(error));
