@if (currentViewState$ | async; as currentViewState) {
	@if (currentViewState === 'loading' || isLoading) {
		<cramo-loading-area [title]="(loadingSlogan$ | async)!" />
	}

	@if (currentViewState === 'marketSelector' && !isLoading) {
		<cramo-select-market />
	}

	@if (currentViewState === 'confirmMarketSwitch' && !isLoading) {
		<cramo-confirm-market-change-page />
	}

	@if (currentViewState === 'noAvailableCustomers' && !isLoading) {
		<cramo-no-available-customers-page />
	}

	@if (currentViewState === 'onboarding' && !isLoading) {
		<cramo-onboarding-page />
	}

	@if (currentViewState === 'landingPage' && !isLoading) {
		<cramo-header />
		@if (isLoggedIn$ | async) {
			<cramo-user-side-nav />
		}
		<router-outlet #outlet="outlet" />
		<cramo-footer />
	}
}
