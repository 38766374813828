<div class="punchout d-flex justify-content-center align-items-center w-100">
	{{ appData().login.punchoutBannerText }}
	<u class="ml-1">
		<a (click)="cancelPunchout()" data-testid="punchout-banner-cancel-button">
			{{ appData().login.punchoutCancelLinkText }}
		</a>
	</u>
	<!-- Below form should be removed whenever we have tested that the createPunchbackForm method works -->
	<form ngNoForm #punchBackform id="punchBackform" method="POST" enctype="application/x-www-form-urlencoded"></form>
</div>
