import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddToCartSuccessDialogComponent } from '../../components/add-to-cart-success-modal/add-to-cart-success-dialog.component';
import { AddToCartSuccessDialogData } from '../../components/add-to-cart-success-modal/types/add-to-cart-success-dialog.types';
import { CartService } from '../cart/cart.service';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	private cartService = inject(CartService);
	private dialog = inject(MatDialog);

	constructor() {
		this.setupCartSuccessDialog();
	}

	private setupCartSuccessDialog() {
		const openAddToCartSuccessDialog = (config: MatDialogConfig<AddToCartSuccessDialogData> = {}) => {
			this.dialog.open(AddToCartSuccessDialogComponent, config);
		};

		this.cartService.cartItemAdded$.subscribe((cartOrderRowId) =>
			openAddToCartSuccessDialog({ data: { cartOrderRowId } }),
		);
	}
}

export function provideDialogService() {
	return { provide: DialogService, useClass: DialogService };
}
