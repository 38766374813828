import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { EpiService } from '../../services/epi/epi.service';
import { PunchoutService } from '../../services/punchout.service';
import { PunchBackMessage } from '../../types/punchout.types';

// TODO -> All comments in this compoonent can be removed whenever you see this.
@Component({
	selector: 'cramo-punchout-banner',
	templateUrl: './punchout-banner.component.html',
	styleUrls: ['./punchout-banner.component.scss'],
	standalone: true,
	imports: [],
})
export class PunchoutBannerComponent {
	private epiService = inject(EpiService);
	protected appData = toSignal(this.epiService.appData$, { requireSync: true });
	private punchoutService = inject(PunchoutService);

	// public punchoutBannerText = 'You are currently renting in PunchOut mode.';
	// public punchoutCancelLinkText = 'Cancel and return';
	// @ViewChild('punchBackform')
	// private punchBackform: ElementRef<HTMLFormElement>;

	protected cancelPunchout() {
		this.punchoutService
			.cancelPunchout()
			.pipe(take(1))
			.subscribe((msg: PunchBackMessage) => {
				this.punchoutService.submitPunchbackForm(msg);
				this.punchoutService.logoutPunchout();
				// this.punchBackform.nativeElement.action = msg.url;
				// this.punchBackform.nativeElement.submit();
			});
	}
}
