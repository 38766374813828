import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import { Component, Inject, inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Environment } from '../environments/environment.types';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingAreaComponent } from './components/loading-area/loading-area.component';
import { SelectMarketComponent } from './components/select-market/select-market.component';
import { UserSideNavComponent } from './components/user-side-nav/user-side-nav.component';
import { MaterialDesignService } from './material-design.service';
import { ConfirmMarketChangePageComponent } from './pages/confirm-market-change-page/confirm-market-change-page.component';
import { NoAvailableCustomersPageComponent } from './pages/no-available-customers-page/no-available-customers-page.component';
import { OnboardingPageComponent } from './pages/onboarding-page/onboarding-page.component';
import { AuthService } from './services/auth.service';
import { ConfigurationService } from './services/configuration.service';
import { EpiService } from './services/epi/epi.service';
import { GtmService } from './services/gtm.service';
import { LoggingService } from './services/logging.service';
import { MarketService } from './services/market.service';
import { MetaService } from './services/meta.service';
import { PunchoutService } from './services/punchout.service';
import { ScriptsService } from './services/scripts.service';
import { UserService } from './services/user/user.service';
import { ViewPickerService, ViewState } from './services/view-picker.service';
import { AppData } from './types/translations.types';
import { User } from './types/user.types';
import { isDotComDomain } from './utils/location';

@Component({
	selector: 'cramo-root',
	templateUrl: './app.component.html',
	standalone: true,
	imports: [
		NgIf,
		SelectMarketComponent,
		LoadingAreaComponent,
		ConfirmMarketChangePageComponent,
		NoAvailableCustomersPageComponent,
		OnboardingPageComponent,
		HeaderComponent,
		UserSideNavComponent,
		RouterOutlet,
		FooterComponent,
		AsyncPipe,
	],
})
export class AppComponent implements OnInit {
	private readonly epiService = inject(EpiService);

	public appData$: Observable<AppData>;
	public user$: Observable<User>;
	public loadingSlogan$: Observable<string>;
	public isLoggedIn$: Observable<boolean>;
	public currentViewState$: Observable<ViewState>;

	public environment: Environment;
	public isLoading = true;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private configuationService: ConfigurationService,
		private metaService: MetaService,
		private router: Router,
		private scriptService: ScriptsService,
		public renderer2: Renderer2,
		private gtmService: GtmService,
		private userService: UserService,
		private viewPickerService: ViewPickerService,
		private authService: AuthService,
		private punchoutService: PunchoutService,
		private materialDesignService: MaterialDesignService,
		private loggingService: LoggingService,
		private marketService: MarketService,
	) {}

	ngOnInit() {
		this.setVersion();
		this.gtmService.sendAppVersionEvent();
		this.materialDesignService.init();

		this.loggingService.init();

		this.punchoutService
			.initializePunchout()
			.pipe(take(1))
			.subscribe((isPunchout) => {
				if (!isPunchout) {
					this.authService.initializeAuthService();
				}
			});

		this.environment = environment;
		this.appData$ = this.epiService.appData$;

		this.currentViewState$ = this.viewPickerService.currentViewState$;

		this.user$ = this.userService.user$;
		this.isLoggedIn$ = this.userService.isLoggedIn$;

		this.loadingSlogan$ = this.epiService.appData$?.pipe(
			map((data) => data.global.loadingSlogan ?? ''),
			startWith(''),
		);

		this.router.events.subscribe((event) => {
			if (!(event instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
			this.setCanonical();
			this.metaService.addSiteVerification();
			this.setNoIndex();
		});

		if (isDotComDomain()){
			this.isLoading = false;
			return;
		}

		this.updateHtmlLangAttribute();

		combineLatest([this.isLoggedIn$, this.appData$, this.epiService.menu$])
			.pipe(
				take(1),
				switchMap(([isUserAuthenticated]) => {
					if (isUserAuthenticated) {
						return this.epiService.getLogisticsData();
					}

					return of(null);
				}),
			)
			.subscribe(() => {
				this.onLoadingDone();
			});
	}

	private setNoIndex(): void {
		if (this.configuationService.configurations.environment !== 'Production') {
			this.metaService.setNoIndex();
		}
	}

	private onLoadingDone(): void {
		this.isLoading = false;
		this.scriptService.injectScripts(this.renderer2);
	}

	private setVersion() {
		const version = this.gtmService.getAppVersion();
		this.renderer2.setAttribute(document.body, 'data-ecom-version', version);
	}

	private setCanonical() {
		this.metaService.removeCanonical();
		this.metaService.addCanonical();
	}

	private updateHtmlLangAttribute() {
		this.document.documentElement.setAttribute('lang', this.marketService.currentLanguage);
	}
}
